<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-block card-stretch card-height">
          <div class="card-header d-flex justify-content-between">
            <div class="header-title">
              <h4 class="card-title mb-0">
                {{ $t("billPaperMsgs.manageBills") }}
              </h4>
            </div>

            <div class="text-right">
              <div
                class="
                  custom-control
                  custom-switch
                  custom-switch-color
                  custom-control-inline
                "
              >
                <input
                  type="checkbox"
                  class="custom-control-input bg-success"
                  id="showArchivedBills"
                  v-model="isArchived"
                />
                <label class="custom-control-label" for="showArchivedBills">{{
                  $t("generalMsgs.showArchived")
                }}</label>
              </div>

              <a
                href="#"
                v-b-popover.hover.top
                :title="$t('billPaperMsgs.refreshBills')"
                @click.prevent="fetchAndSetBills(currentPage)"
                class="btn"
              >
                <i
                  class="fas fa-redo-alt"
                  :class="isLoading ? 'fa-spin' : ''"
                />
              </a>

              <a
                href="#"
                class="btn btn-primary"
                @click.prevent="openUploadBillModal()"
              >
                {{ $t("billPaperMsgs.uploadNew") }}
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <div class="mm-search-bar col-md-6 mb-3">
                <div class="searchbox">
                  <a class="search-link" href="#">
                    <i class="fas fa-search"></i>
                  </a>
                  <input
                    type="text"
                    class="form-control search-input"
                    v-model="searchTerm"
                    :placeholder="`${$t('billPaperMsgs.searchBills')}...`"
                    @input="onSearch"
                  />
                </div>
              </div>

              <b-table
                :fields="tableColumns"
                :items="allBills"
                :busy="isLoading"
                head-variant="primary"
                hover
                responsive
                no-local-sorting
                @sort-changed="onSortChange"
              >
                <template #cell(file_name)="data">
                  <a
                    href="#"
                    @click.prevent="downloadBillFile(data.item.pdf_url, data.item.id)"
                    >{{ createBillFileName(data.item) }}</a
                  >
                </template>

                <template #cell(status)="data">
                  <b-badge
                    :class="`border border-${
                      billStatusColors[data.item.status]
                    } text-${billStatusColors[data.item.status]}`"
                    variant="none"
                    v-b-popover.hover.top
                    :title="
                      $t(`billPaperMsgs.parserStatusesDesc.${data.item.status}`)
                    "
                    >{{
                      $t(`billPaperMsgs.parserStatuses.${data.item.status}`)
                    }}
                  </b-badge>
                </template>

                <template #cell(order_number)="data">
                  <span v-if="data.item.status !== BILL_STATUSES.PARSING">
                    <a
                      href="#"
                      @click.prevent="openOrderDetailsModal(data.item.order)"
                      v-if="data.item.order"
                    >
                      {{ data.item.order_number }}
                    </a>
                    <span v-else>{{ data.item.order_number }}</span>
                  </span>
                </template>

                <template #cell(created_at)="data">
                  {{ formatDateTime(data.item.created_at) }}
                </template>

                <template #cell(date)="data">
                  {{
                    data.item.status !== BILL_STATUSES.PARSING && data.item.date
                      ? formatDate(data.item.date)
                      : ""
                  }}
                </template>

                <template #cell(drawing_number)="data">
                  <span v-if="data.item.status !== BILL_STATUSES.PARSING">{{
                    data.item.drawing_number
                  }}</span>
                </template>

                <template #cell(labor_revenue)="data">
                  {{
                    data.item.status !== BILL_STATUSES.PARSING
                      ? $n(data.item.labor_revenue || 0, "currency", "de-DE")
                      : ""
                  }}
                </template>

                <template #cell(material_revenue)="data">
                  {{
                    data.item.status !== BILL_STATUSES.PARSING
                      ? $n(data.item.material_revenue || 0, "currency", "de-DE")
                      : ""
                  }}
                </template>

                <template #cell(uploadedBy)="data">
                  <span v-if="data.item.created_by">
                    {{ data.item.created_by.first_name }}
                    {{ data.item.created_by.last_name }}
                  </span>
                </template>

                <template #cell(lastDownloadedAt)="data">
                  <span v-if="data.item.last_downloaded_at">
                    {{ formatDateTime(data.item.last_downloaded_at) }}
                  </span>
                  <span v-else>{{ $t("never") }}</span>
                </template>

                <template #head(actions)="">
                  <span></span>
                </template>
                <template #cell(actions)="data" style="min-width: 50px">
                  <!-- <router-link
                    :to="{
                      name: 'edit-bill',
                      params: { id: data.item.id },
                    }"
                    class="svg-icon mr-2"
                  >
                    <i class="far fa-edit text-info" />
                  </router-link> -->

                  <a
                    href="#"
                    @click.prevent="
                      data.item.status !== BILL_STATUSES.PARSING
                        ? reparseBill(data.item.id)
                        : null
                    "
                    class="mr-2"
                    tabindex="0"
                    v-b-popover.hover.top
                    :title="$t('billPaperMsgs.reparseBill')"
                    v-if="
                      data.item.status !== BILL_STATUSES.PARSING && !isArchived
                    "
                  >
                    <i
                      class="fas fa-sync-alt text-info"
                      :class="
                        data.item.status === BILL_STATUSES.PARSING
                          ? 'fa-spin'
                          : ''
                      "
                    />
                  </a>

                  <a
                    href="#"
                    @click.prevent="confirmArchiveBill(data.item.id)"
                    class="mr-2"
                    tabindex="0"
                    v-if="
                      !isArchived && data.item.status !== BILL_STATUSES.PARSING
                    "
                    v-b-popover.hover.top
                    :title="$t('billPaperMsgs.archiveBill')"
                  >
                    <i class="fas fa-trash-alt text-danger" />
                  </a>

                  <a
                    href="#"
                    @click.prevent="confirmUnarchiveBill(data.item.id)"
                    class="mr-2"
                    tabindex="0"
                    v-if="isArchived"
                    v-b-popover.hover.top
                    :title="$t('billPaperMsgs.unarchiveBill')"
                  >
                    <i class="fas fa-trash-restore-alt text-success" />
                  </a>
                </template>
              </b-table>

              <div class="d-flex justify-content-end">
                <b-form-select
                  class="form-control form-control-b-select mr-2"
                  style="height: 38px; width: 5rem"
                  v-model="pageSize"
                  :options="perPageChoices"
                  @input="onPerPageChange"
                >
                  <template #first>
                    <b-form-select-option :value="-1" disabled>
                      {{ $t("billPaperMsgs.billsPerPage") }}
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <b-pagination
                  :value="currentPage"
                  :total-rows="totalBills"
                  :per-page="pageSize || totalBills"
                  @change="onPageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <upload-bill-modal
      :showModal.sync="modals.showUploadBillModal"
      @close="hideUploadBillModal"
      @closeUpdate="hideUpdateUploadBillModal"
    />

    <order-detail-modal
      :showModal.sync="modals.showOrderDetailsModal"
      :orderId="modals.selectedOrderDetailsId"
      :orderNum="modals.selectedOrderNum"
      :selectedTabIndex="0"
      @close="hideOrderDetailsModal"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { debounce, get } from "lodash";
import {
  USER_ROLES,
  BILL_STATUSES,
} from "../../common/constants";
import {
  formatDateTime,
  parseFileNameFromUrl,
  formatDate,
} from "../../common/utils";
import UploadBillModal from "./UploadBillModal";
import OrderDetailModal from "../Orders/OrderDetail/OrderDetailModal";

export default {
  name: "BillList",

  components: { UploadBillModal, OrderDetailModal },

  data() {
    return {
      BILL_STATUSES,

      allBills: [],
      currentPage: 1,
      pageSize: 20,
      totalBills: 0,

      isLoading: false,
      isArchived: false,
      ordering: "",
      searchTerm: "",

      billStatusColors: {
        success: "success",
        parsing: "secondary",
        error_download: "danger",
        error_parse: "danger",
        error_order_not_found: "danger",
        error_duplicate_bill: "warning",
      },

      modals: {
        showUploadBillModal: false,
        showOrderDetailsModal: false,
        selectedOrderDetailsId: null,
        selectedOrderNum: null,
      },

      perPageChoices: [
        { value: 10, text: "10" },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 50, text: "50" },
        { value: null, text: "All" },
      ],
    };
  },

  methods: {
    ...mapActions([
      "getBillPapers",
      "downloadFile",
      "reparseBillPaper",
      "archiveBillPaper",
      "unarchiveBillPaper",
      "updateBillDownloadedAt"
    ]),
    formatDateTime,
    parseFileNameFromUrl,
    formatDate,

    async fetchAndSetBills(pageNum = 1, params = {}) {
      this.isLoading = true;

      const response = await this.getBillPapers({
        ...(this.pageSize && {
          limit: this.pageSize,
          offset: (pageNum - 1) * this.pageSize,
        }),
        is_archived: this.isArchived,
        ...(this.ordering && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        ...params,
        ...(this.getLoggedInUser.role === USER_ROLES.CALCULATOR && { created_by: this.getLoggedInUser.id })
      });
      this.allBills = this.pageSize ? response.data.results : response.data;
      this.currentPage = pageNum;
      this.totalBills = this.pageSize
        ? response.data.count
        : get(this.allBills, "length", 0);
      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchAndSetBills(pageNum);
    },

    onPerPageChange() {
      this.fetchAndSetBills();
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? "-" + context.sortBy : context.sortBy;
      this.fetchAndSetBills();
    },

    onSearch() {
      this.debouncedSearchBills(this);
    },

    debouncedSearchBills: debounce((vm) => {
      vm.fetchAndSetBills();
    }, 500),

    openUploadBillModal() {
      this.modals.showUploadBillModal = true;
    },

    hideUploadBillModal() {
      this.modals.showUploadBillModal = false;
    },

    hideUpdateUploadBillModal() {
      this.hideUploadBillModal();
      this.fetchAndSetBills(this.currentPage);
    },

    openOrderDetailsModal(order) {
      this.modals.selectedOrderDetailsId = order.id;
      this.modals.selectedOrderNum = order.order_number;
      this.modals.showOrderDetailsModal = true;
    },

    hideOrderDetailsModal() {
      this.modals.showOrderDetailsModal = false;
      this.modals.selectedOrderDetailsId = this.modals.selectedOrderNum = null;
    },

    async confirmArchiveBill(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("billPaperMsgs.archiveConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.archiveBillPaper(id);
          const refreshPage =
            this.allBills.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetBills(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.archivedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    async confirmUnarchiveBill(id) {
      try {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("billPaperMsgs.unarchiveConfirm"),
          {
            title: this.$t("generalMsgs.suretyMsg"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          await this.unarchiveBillPaper(id);
          const refreshPage =
            this.allBills.length > 1 ? this.currentPage : this.currentPage - 1;
          this.fetchAndSetBills(refreshPage || 1);
          this.makeToast("success", this.$t("generalMsgs.unarchivedSuccess"));
        }
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    async reparseBill(id) {
      try {
        await this.reparseBillPaper(id);
        this.fetchAndSetBills(this.currentPage);
        this.makeToast("success", this.$t("billPaperMsgs.reparseStarted"));
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }
    },

    async downloadBillFile(fileUrl, billId) {
      const h = this.$createElement;

      this.makeToast(
        "primary",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadingFile"),
        ]),
        "b-toaster-bottom-left"
      );

      let bill = this.allBills.find(bill => bill.id === billId);
      let fileName = null;
      if (bill.order_number && bill.drawing_number) {
        fileName = `${bill.order_number.replaceAll('/', '_')}_${bill.drawing_number.replaceAll('/', '_')}.pdf`;
      }
      await this.downloadFile({ fileUrl: fileUrl, removeTimestamp: true, fileName: fileName });
      const downloadedAt = get((await this.updateBillDownloadedAt(billId)), 'data.data.downloaded_at');
      bill['last_downloaded_at'] = downloadedAt;

      this.makeToast(
        "success",
        h("span", [
          h("i", { class: "fas fa-download mr-2" }),
          this.$t("generalMsgs.downloadFileSuccess"),
        ]),
        "b-toaster-bottom-left"
      );
    },

    createBillFileName(bill) {
      let fileName = null;
      if (bill.order_number && bill.drawing_number) {
        fileName = `${bill.order_number.replaceAll('/', '_')}_${bill.drawing_number.replaceAll('/', '_')}.pdf`;
      } else {
        fileName = parseFileNameFromUrl(bill.pdf_url, true);
      }
      return fileName;
    },

    makeToast(variant = null, msg, toaster = undefined) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
        ...(toaster && { toaster }),
      });
    },
  },

  async mounted() {
    this.fetchAndSetBills();
  },

  computed: {
    ...mapGetters(["getLoggedInUser"]),

    tableColumns() {
      if (
        [
          USER_ROLES.ADMIN,
          USER_ROLES.OFFICE_WORKER,
          USER_ROLES.PROJECT_MANAGER,
        ].includes(this.getLoggedInUser.role)
      ) {
        return [
          {
            key: "file_name",
            label: this.$t("billPaperMsgs.file"),
            sortable: true,
          },
          { key: "status", label: this.$t("status") },

          // Parsed Data.
          {
            key: "order_number",
            label: this.$t("orderMsgs.orderNum"),
            sortable: true,
          },
          {
            key: "date",
            label: this.$t("billPaperMsgs.billDate"),
            sortable: true,
          },
          {
            key: "drawing_number",
            label: this.$t("billPaperMsgs.sheet"),
            sortable: true,
          },
          {
            key: "labor_revenue",
            label: this.$t("orderMsgs.laborRev"),
            sortable: true,
          },
          {
            key: "material_revenue",
            label: this.$t("orderMsgs.materialRev"),
            sortable: true,
          },

          {
            key: "created_at",
            label: this.$t("billPaperMsgs.uploadedAt"),
            sortable: true,
          },
          {
            key: "lastDownloadedAt",
            label: this.$t("billPaperMsgs.lastDownloaded"),
          },
          { key: "uploadedBy", label: this.$t("billPaperMsgs.uploadedBy") },

          {
            key: "actions",
            tdClass: "text-right",
            thStyle: { minWidth: "80px" },
          },
        ];
      } else {
        return [
          {
            key: "file_name",
            label: this.$t("billPaperMsgs.file"),
            sortable: true,
          },
          {
            key: "order_number",
            label: this.$t("orderMsgs.orderNum"),
            sortable: true,
          },
          {
            key: "created_at",
            label: this.$t("billPaperMsgs.uploadedAt"),
            sortable: true,
          },
          {
            key: "lastDownloadedAt",
            label: this.$t("billPaperMsgs.lastDownloaded"),
          },
          { key: "status", label: this.$t("status") },

          {
            key: "actions",
            tdClass: "text-right",
            thStyle: { minWidth: "80px" },
          },
        ];
      }
    },
  },

  watch: {
    isArchived: function () {
      this.fetchAndSetBills();
    },
  },
};
</script>
