<template>
  <div class="container-fluid">
    <b-modal
      ref="upload-bill-modal"
      size="lg"
      :title="$t('billPaperMsgs.uploadNewBill')"
      hide-footer
      centered
      static
      lazy
      scrollable
      :no-close-on-backdrop="!!billFiles.length"
      :no-close-on-esc="!!billFiles.length"
      @close="closeModal"
      @hide="hideModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="form-group col-md-12">
                  <label for="billFile">{{
                    $t("billPaperMsgs.billFiles")
                  }}</label>
                  <b-form-file
                    :placeholder="$t('billPaperMsgs.chooseBillPlaceholder')"
                    :drop-placeholder="$t('billPaperMsgs.billDropPlaceholder')"
                    :browse-text="$t('generalMsgs.browse')"
                    :disabled="isLoading"
                    v-model="billFileBuff"
                    class="form-control"
                    accept=".pdf"
                    multiple
                  ></b-form-file>

                  <!-- Attached files -->
                  <div class="mt-2">
                    <div
                      class="row"
                      v-for="(fileData, index) in billFiles"
                      :key="index"
                    >
                      <div
                        :class="
                          fileData.isUploading && fileData.isS3Uploading
                            ? 'col-md-8'
                            : 'col-md-10'
                        "
                      >
                        <i class="fas fa-file text-primary" />
                        {{ fileData.file.name }}
                      </div>
                      <div
                        class="col-md-2 text-right"
                        v-if="fileData.isUploading"
                      >
                        <b-progress
                          :value="fileData.uploadPercent"
                          :max="100"
                          animated
                          class="mt-1"
                        ></b-progress>
                      </div>
                      <div class="text-right col-md-2">
                        <a
                          href="#"
                          class="text-danger"
                          @click.prevent="removeAttachedBillFile(index)"
                          v-if="fileData.isS3Uploading"
                        >
                          <i class="fas fa-times-circle" /> {{ $t("remove") }}
                        </a>
                        <a
                          href="#"
                          class="text-success"
                          v-if="
                            !fileData.isUploading &&
                            fileData.uploadPercent === 100
                          "
                        >
                          <i class="fas fa-check-circle" />
                          {{ $t("generalMsgs.success") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-right">
                <b-button
                  type="submit"
                  variant="primary"
                  :disabled="isLoading"
                  style="min-width: 100px"
                >
                  <i v-if="isLoading" class="fas fa-circle-notch fa-spin" />
                  <span v-else>{{ $t("billPaperMsgs.upload") }}</span>
                </b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
import { get } from "lodash";

export default {
  name: "UploadBillModal",

  props: {
    showModal: Boolean,
  },

  data() {
    return {
      billFiles: [],
      billFileBuff: [],

      isLoading: false,
      uploadCancelTokenSource: null,
    };
  },

  methods: {
    ...mapActions([
      "getBillPaperPresignedUrl",
      "createBillPaper",
      "parseBillPaper",
      "uploadToPresignedUrl",
    ]),

    resetData() {
      this.billFileBuff = this.billFiles = [];
      this.isLoading = false;
    },

    removeAttachedBillFile(fileIndex) {
      if (this.billFiles[fileIndex].uploadCancelTokenSource) {
        this.billFiles[fileIndex].uploadCancelTokenSource.cancel();
      }

      this.billFiles.splice(fileIndex, 1);
    },

    async closeModal(event) {
      event.preventDefault();

      if (this.billFiles.length) {
        const isConfirmed = await this.$bvModal.msgBoxConfirm(
          this.$t("generalMsgs.cancelPendingFilesUploadPopup"),
          {
            title: this.$t("generalMsgs.cancelUpload"),
            size: "md",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: this.$t("generalMsgs.yes"),
            cancelTitle: this.$t("generalMsgs.no"),
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        );
        if (isConfirmed) {
          // Canceling any ongoing upload on modal close.
          this.billFiles.forEach((fileData) => {
            if (fileData.uploadCancelTokenSource) {
              fileData.uploadCancelTokenSource.cancel();
            }
          });
          this.hideModalManual();
        }
      } else {
        this.hideModalManual();
      }
    },

    closeAndReset() {
      this.$emit("close");
      this.resetData();
    },

    hideModal(event) {
      if (event.trigger !== "headerclose") {
        this.$emit("close");
        this.resetData();
      }
    },

    hideModalManual() {
      this.$nextTick(() => {
        this.$refs["upload-bill-modal"].hide();
      });
      this.closeAndReset();
    },

    hideWithUpdateModal() {
      if (this.uploadCancelTokenSource) {
        this.uploadCancelTokenSource.cancel();
      }

      this.$refs["upload-bill-modal"].hide();
      this.$emit("closeUpdate");
      this.resetData();
    },

    async uploadFile(fileData, url) {
      fileData["url"] = url.split("?")[0];
      fileData.isUploading = true;
      fileData.uploadPercent = 0;

      try {
        await this.uploadToPresignedUrl({
          url,
          file: fileData.file,
          config: {
            onUploadProgress: function (progressEvent) {
              this.uploadPercent = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
            }.bind(fileData),
            cancelToken: fileData.uploadCancelTokenSource.token,
          },
        });
        fileData.isS3Uploading = false;
        await this.parseBillPaper({ pdf_url: fileData.url });
      } catch (error) {
        fileData.isS3Uploading = false;
      }

      fileData.uploadCancelTokenSource = null;
      fileData.isUploading = false;
      fileData.uploadPercent = 100;
    },

    async uploadBillFiles(files) {
      try {
        const urlResp = await this.getBillPaperPresignedUrl(
          files.map((fileData) => ({
            file_name: fileData.file.name,
            content_type: fileData.file.type,
          }))
        );

        await Promise.all(
          files.map((fileData, index) =>
            this.uploadFile(fileData, urlResp.upload_urls[index])
          )
        );
      } catch (error) {
        // Handle upload error here (if needed).
      }
    },

    async onSubmit() {
      this.isLoading = true;

      if (this.billFiles.length) {
        try {
          await this.uploadBillFiles(this.billFiles);

          this.makeToast("success", this.$t("billPaperMsgs.billUploadSuccess"));
          this.isLoading = false;
          this.hideWithUpdateModal();
        } catch (error) {
          this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        }
      } else {
        this.makeToast("danger", this.$t("generalMsgs.noFileSelected"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$root.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  async mounted() {},

  computed: {},

  watch: {
    showModal(value) {
      if (value) {
        this.$refs["upload-bill-modal"].show();
      }
    },
    async billFileBuff(filesVal) {
      if (get(filesVal, "length")) {
        const newFiles = filesVal.map((file) => ({
          file,
          isUploading: false,
          isS3Uploading: true,
          uploadPercent: 0,
          uploadCancelTokenSource: axios.CancelToken.source(),
        }));
        this.billFileBuff = [];
        this.billFiles = this.billFiles.concat(newFiles);
      }
    },
  },
};
</script>
